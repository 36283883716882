import React, { useEffect, useState } from 'react';
import { checkProductExists } from '../utils/cartUtils';
import { Link } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import ImageZoom from './ImageZoom/ImageZoom';
import './ProductCard.css';

const ProductCard = ({ product, isShopCard = false }) => {
    const isLessThan365 = useMediaQuery('(max-width: 365px)');
    const { t } = useTranslation();
    const [isProductAdded, setIsProductAdded] = useState(false);
    const { addToCart } = useCart();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isFading, setIsFading] = useState(false); // Controls fade animation

    useEffect(() => {
        checkProduct();
    }, []);

    const checkProduct = () => {
        const isTrue = checkProductExists(product);
        setIsProductAdded(isTrue);
    };

    // Image switching logic with fade animation
    const handleNextImage = () => {
        if (product.product_images && product.product_images.length > 1) {
            setIsFading(true); // Start fade out
            setTimeout(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % product.product_images.length);
                setIsFading(false); // Fade in after image has changed
            }, 300); // Adjust timing to match CSS transition duration
        }
    };

    const handlePrevImage = () => {
        if (product.product_images && product.product_images.length > 1) {
            setIsFading(true);
            setTimeout(() => {
                setCurrentImageIndex(
                    (prevIndex) => (prevIndex - 1 + product.product_images.length) % product.product_images.length
                );
                setIsFading(false);
            }, 300);
        }
    };

    // Automatic image rotation with animation, only if there are multiple images
    useEffect(() => {
        if (product.product_images && product.product_images.length > 1) {
            const interval = setInterval(() => {
                handleNextImage();
            }, 5000);

            return () => clearInterval(interval); // Cleanup interval on unmount
        }
    }, [product.product_images]);

    return (
        <div className={`bg-white rounded-lg p-2 border border-gray-200 relative shadow-lg hover:shadow-xl ${isLessThan365 ? "max-w-[10rem]" : "max-w-[20rem]"}`}>
            <Link to={`/view/products/${product.slug}/`} className="w-full">
                <div className="relative">{
                    // isShopCard ? (
                    //     <div className="relative items-end flex flex-row justify-center w-full h-24 md:h-48 object-contain rounded-lg aspect-square">
                    //         <ImageZoom
                    //             src={product.product_images && product.product_images.length > 0
                    //                 ? product.product_images[currentImageIndex].image
                    //                 : "/default-product.png"}
                    //             className={`w-full h-24 md:h-48 object-contain rounded-lg ${isFading ? 'fade' : ''}`}
                    //         />

                    //         {parseFloat(product.discount_percentage) > 0 && (
                    //             <div className="absolute top-2 right-2 bg-red-500 text-white text-xs py-1 px-2 rounded-md">
                    //                 {parseInt(product.discount_percentage)}% Off
                    //             </div>
                    //         )}
                    //     </div>
                    // ) : (
                    <img
                        src={product.product_images && product.product_images.length > 0
                            ? product.product_images[0].image
                            : "/default-product.png"}
                        alt={product.product_images && product.product_images.length > 0
                            ? product.product_images[0].alt_text
                            : "No image available"}
                        className="w-full h-24 md:h-48 object-contain rounded-lg"
                    />
                    // )
                }
                    {parseFloat(product.discount_percentage) > 0 && (
                        <div className="absolute top-2 right-2 bg-red-500 text-white text-xs md:text-md font-bold py-1 px-2 rounded-md">
                            {parseInt(product.discount_percentage)}% Off
                        </div>
                    )}
                    {product.availability_status === "LIMITED_STOCK" && (
                        <div className="absolute top-2 left-2 bg-yellow-500 text-white text-xs py-1 px-2 rounded-md">
                            LIMITED STOCK
                        </div>
                    )}
                    {product.availability_status === "PREORDER" && (
                        <div className="absolute top-2 left-2 bg-blue-700 text-white text-xs py-1 px-2 rounded-md">
                            PREORDER
                        </div>
                    )}
                    {product.availability_status === "OUT_OF_STOCK" && (
                        <div className="absolute top-2 left-2 bg-orange-600 text-white text-xs py-1 px-2 rounded-md">
                            OUT OF STOCK
                        </div>
                    )}
                    {product.availability_status === "DISCONTINUED" && (
                        <div className="absolute top-2 left-2 bg-red-700 text-white text-xs py-1 px-2 rounded-md">
                            DISCONTINUED
                        </div>
                    )}
                </div>
            </Link>
            <Link to={`/view/products/${product.slug}/`} className="w-full">
                <div className="mt-4">
                    <h3 className="text-sm md:text-lg font-semibold truncate">
                        {product.product_name}
                    </h3>
                    <Link to={`/shop/partners/${product.manufacturer_slug}`} className="text-primary text-xs hover:underline">
                        {product.manufacturer}
                    </Link>
                    {
                        product.availability_status !== "OUT_OF_STOCK" && product.availability_status !== "DISCONTINUED" ?

                            <div className="flex items-center">
                                <span className="text-md md:text-xl font-bold text-gray-900">₹{product.price_string}</span>
                                {parseFloat(product.discount_percentage) > 0 && (
                                    <span className="ml-2 text-xs text-gray-500 line-through">₹{parseInt(product.mrp) || product.mrp}</span>
                                )}
                            </div> : <></>
                    }
                </div>
            </Link>
            {
                product.availability_status !== "OUT_OF_STOCK" && product.availability_status !== "DISCONTINUED" ?
                    <div className="mt-2 flex justify-between items-center">
                        {!isProductAdded ? (
                            <button
                                onClick={() => {
                                    addToCart(product);
                                    checkProduct();
                                }}
                                className="text-xs md:text-lg w-full bg-primary text-white py-2 px-4 rounded hover:bg-green-700 transition-colors font-bold"
                            >
                                {t("addToCart")}
                            </button>
                        ) : (
                            <Link to='/cart' className='w-full'>
                                <button
                                    className="w-full bg-primary text-white py-2 px-4 rounded hover:bg-green-700 transition-colors"
                                >
                                    {t("goToCart")}
                                </button>
                            </Link>
                        )}
                    </div> : <></>
            }
        </div>
    );
};

export default ProductCard;
