import React, { useRef, useState } from "react";

const HorizontalList = ({ children, buttonSize = 5 }) => {
    const scrollContainerRef = useRef(null);

    // Scroll using buttons
    const scrollLeftBy = (distance) => {
        scrollContainerRef.current.scrollBy({
            left: -distance,
            behavior: "smooth",
        });
    };

    const scrollRightBy = (distance) => {
        scrollContainerRef.current.scrollBy({
            left: distance,
            behavior: "smooth",
        });
    };

    return (
        <div className="relative flex items-center w-full overflow-hidden">
            {/* Left Scroll Button */}
            <button
                className="absolute left-2 z-10 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700"
                style={{
                    width: `${buttonSize * 10}px`,
                    height: `${buttonSize * 10}px`,
                }}
                onClick={() => scrollLeftBy(300)}
            >
                &lt;
            </button>

            {/* Scrollable Container */}
            <div
                ref={scrollContainerRef}
                className="flex overflow-x-auto space-x-4 mb-4 mx-2 md:mx-12 pb-4 px-2 scroll-smooth no-scrollbar cursor-grab"
            >
                {children}
            </div>

            {/* Right Scroll Button */}
            <button
                className="absolute right-2 z-10 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700"
                style={{
                    width: `${buttonSize * 10}px`,
                    height: `${buttonSize * 10}px`,
                }}
                onClick={() => scrollRightBy(300)}
            >
                &gt;
            </button>
        </div>
    );
};

export default HorizontalList;
