import React, { useState, useEffect } from 'react';
import { getCartItems, updateCartQuantity, clearCart } from '../utils/cartUtils';
import emptyCart from "../assets/Navbar/empty_cart.gif";
import CustomRowText from '../components/CustomRowText';
import { useMediaQuery } from '@mui/material';
import { useCart } from '../contexts/CartContext';
import { getProducts } from '../services/product';
import ProductCard from '../components/ProductCard';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getUserToken, saveUserToken } from '../utils/loginUtils';
import HorizontalList from '../components/HorizontalList/HorizontalList';

const Cart = () => {
    const { t } = useTranslation();
    const isTab = useMediaQuery('(max-width: 966px)');
    const [cart, setCart] = useState([]);
    const [products, setProducts] = useState([]);
    const [priceDetails, setPriceDetails] = useState({
        itemsCount: 0,
        mrp: 0,
        discount: 0,
        additionalDiscount: 0,
        deliveryCharges: 80,
        totalSavings: 0,
        totalPayable: 0
    });

    // Load cart items when component mounts
    useEffect(() => {
        const cartItems = getCartItems();
        setCart(cartItems);
        calculatePriceDetails(cartItems);
        const fetchRequired = async () => {
            const prods = await getProducts({ filter: "" });
            setProducts(prods.data.results);
        };
        fetchRequired();
    }, []);

    // Function to calculate price details based on cart items
    const calculatePriceDetails = (cartItems) => {
        let mrp = 0;
        let discount = 0;
        let totalPayable = 0;
        let deliveryCharges = 0;

        cartItems.forEach(item => {
            const itemTotalPrice = item.mrp * item.quantity;
            const itemTotalPay = item.price * item.quantity;
            mrp += itemTotalPrice;
            totalPayable += itemTotalPay;
            // Calculate discount based on item.discount and discount_percentage
            const itemDiscountPercentage = item.discount_percentage ? item.discount_percentage / 100 : 0;
            const itemDiscount = itemTotalPrice * itemDiscountPercentage; // Calculate discount using percentage
            discount += itemDiscount;

            // If there's an additional item.discount (flat), add that too
            discount += item.discount ? item.discount * item.quantity : 0;
        });

        deliveryCharges = totalPayable >= 500 ? 0 : 80;
        // Fix mrp and total payable to two decimal points
        mrp = parseFloat(mrp.toFixed(2));

        setPriceDetails({
            itemsCount: cartItems.length,
            mrp: mrp,
            discount: discount.toFixed(2), // Total discount
            discountPercentage: ((discount / mrp) * 100).toFixed(2), // Calculate overall discount percentage
            additionalDiscount: 0, // If you have additional discount logic
            deliveryCharges: deliveryCharges === 0 ? 'FREE' : deliveryCharges,
            totalSavings: discount.toFixed(2),
            totalPayable: totalPayable
        });
    };

    const { removeFromCart } = useCart();

    const handleRemoveFromCart = (productId) => {
        removeFromCart(productId);
        const updatedCart = getCartItems();
        setCart(updatedCart);
        calculatePriceDetails(updatedCart);
    };

    const handleUpdateQuantity = (productId, quantity) => {
        if (quantity < 1) handleRemoveFromCart(productId);
        updateCartQuantity(productId, quantity);
        const updatedCart = getCartItems();
        setCart(updatedCart);
        calculatePriceDetails(updatedCart);
    };

    const handleClearCart = () => {
        clearCart();
        setCart([]);
        calculatePriceDetails([]);
    };


    useEffect(
        () => {
            if (getUserToken().length === 0) {
                const searchParam = window.location.search;
                if (searchParam.includes("token")) {
                    const token = searchParam.replaceAll("?token=", "");
                    saveUserToken(token);
                }
            }
        }, []);

    return (
        <div className="mx-4 md:mx-8 mt-32">
            {cart.length === 0 ? (
                <div className="flex flex-col items-center justify-center min-h-[60vh] space-y-6">
                    {/* Empty Cart Image */}
                    <img
                        src={emptyCart}
                        alt="Empty Cart"
                        className="h-48"
                    />

                    {/* Empty Cart Message */}
                    <h2 className="text-2xl font-semibold text-gray-700">
                        {t("yourCartIsEmpty")}
                    </h2>
                    <p className="text-gray-500 text-center">
                        {t("looksLikeYouHaventAddedAnythingToYourCartYet")}
                    </p>

                    {/* Shop Now Button */}
                    <button
                        onClick={() => window.location.href = '/'}  // Redirect to home
                        className="bg-primary text-white px-6 py-3 rounded-md hover:bg-primary-dark transition-colors text-lg font-bold"
                    >
                        {t("shopNow")}
                    </button>
                </div>
            ) : (
                <>
                    {/* Cart Table */}
                    <div className="hidden sm:flex overflow-x-auto shadow-md border rounded-lg">
                        <table className="min-w-full border-gray-200">
                            <thead className="mb-4 border rounded-lg">
                                <tr>
                                    <th className="px-4 py-2">{t("sNo")}</th>
                                    <th className="px-4 py-2">{t("image")}</th>
                                    <th className="px-4 py-2">{t("productName")}</th>
                                    <th className="px-4 py-2">{t("price")}</th>
                                    <th className="px-4 py-2">{t("quantity")}</th>
                                    <th className="px-4 py-2">{t("remove")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cart.map((item, index) => (
                                    <tr key={item.id}>
                                        <td className="px-2 py-1 text-center font-semibold">{index + 1}</td>
                                        <td className="px-2 py-1 text-center items-center">
                                            <img
                                                src={item.product_images && item.product_images.length > 0
                                                    ? item.product_images[0].image
                                                    : "/default-item.png"}
                                                alt={item.product_images && item.product_images.length > 0
                                                    ? item.product_images[0].alt_text
                                                    : "img"}
                                                className="w-16 h-16 object-cover mx-auto"
                                            />
                                        </td>
                                        <td className="px-2 py-1 truncate max-w-10">{item.product_name}</td>
                                        <td className="px-2 py-1 text-center">₹ {parseInt(item.price) || item.price}</td>
                                        <td className="px-2 py-1">
                                            <div className="flex items-center justify-center">
                                                <button
                                                    onClick={() => handleUpdateQuantity(item.id, item.quantity - 1)}
                                                    className="h-8 w-8 text-white bg-primary hover:bg-primary-dark rounded-full"
                                                >
                                                    -
                                                </button>
                                                <input
                                                    type="text"
                                                    value={item.quantity}
                                                    readOnly
                                                    className="w-12 text-center mx-2 py-1 border rounded"
                                                />
                                                <button
                                                    onClick={() => handleUpdateQuantity(item.id, item.quantity + 1)}
                                                    className="w-8 h-8 text-white bg-primary hover:bg-primary-dark rounded-full"
                                                >
                                                    +
                                                </button>
                                            </div>
                                        </td>
                                        <td className="px-2 py-1 text-center">
                                            <button
                                                onClick={() => handleRemoveFromCart(item.id)}
                                                className="text-red-500 hover:text-red-700"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    className="w-6 h-6 mx-auto"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M19 7l-1 12a2 2 0 01-2 2H8a2 2 0 01-2-2L5 7m5 4v6m4-6v6m1-10V5a1 1 0 00-1-1h-4a1 1 0 00-1 1v2m5 0H6"
                                                    />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex sm:hidden shadow-md border flex-col bg-white rounded-lg p-4">
                        {cart.map((item, index) => (
                            <div key={item.id} className='border-b-2 border-black py-2 mb-2'>
                                <div className="flex fex-row gap-4 items-center">
                                    <span className='font-bold'>
                                        {index + 1}.
                                    </span>
                                    <img
                                        src={item.product_images && item.product_images.length > 0
                                            ? item.product_images[0].image
                                            : "/default-item.png"}
                                        alt={item.product_images && item.product_images.length > 0
                                            ? item.product_images[0].alt_text
                                            : "img"}
                                        className="w-20 h-20 object-contain mx-auto"
                                    />
                                    <div className="flex flex-col items-start flex-1">
                                        <span className='line-clamp-3'>
                                            {item.product_name}
                                        </span>
                                        <span className="px-2 py-1 text-center font-bold">₹ {parseInt(item.price) || item.price}</span>
                                        <div className="flex items-center justify-center">
                                            <button
                                                onClick={() => handleUpdateQuantity(item.id, item.quantity - 1)}
                                                className="md:w-8 md:h-8 px-2 md:px-0 text-white bg-primary hover:bg-primary-dark rounded-full"
                                            >
                                                -
                                            </button>
                                            <input
                                                type="text"
                                                value={item.quantity}
                                                readOnly
                                                className="md:w-8 md:h-8 w-12 text-center mx-2 py-1 border rounded text-xs md: text-md"
                                            />
                                            <button
                                                onClick={() => handleUpdateQuantity(item.id, item.quantity + 1)}
                                                className="md:w-8 md:h-8 px-2 md:px-3s text-white bg-primary hover:bg-primary-dark rounded-full"
                                            >
                                                +
                                            </button>
                                            <button
                                                onClick={() => handleRemoveFromCart(item.id)}
                                                className="text-red-500 hover:text-red-700 ml-4"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    className="w-6 h-6 mx-auto"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M19 7l-1 12a2 2 0 01-2 2H8a2 2 0 01-2-2L5 7m5 4v6m4-6v6m1-10V5a1 1 0 00-1-1h-4a1 1 0 00-1 1v2m5 0H6"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-row justify-end mt-2">
                        <p className='text-md text-red-700 font-bold'>
                            {t("Get5additionaldiscountonPrepaidorders")}
                        </p>
                    </div>
                    <div className="flex flex-col sm:flex-row items-end gap-2 justify-end mt-2 sm:items-center">
                        <span className='text-primary text-md font-semibold mr-1'>
                            {t("youSaved")}
                        </span>
                        <span className='text-primary text-lg font-bold mr-4'>
                            ₹{parseInt(priceDetails.totalSavings) || priceDetails.totalSavings}
                        </span>
                        <Link to="/order" className='w-full sm:w-max'>
                            <button
                                className={`bg-green-500 ${isTab ? "w-full" : ""} text-white py-2 px-8 rounded hover:bg-green-700 transition-colors font-bold text-xl`}
                            >
                                {t("placeOrder")}
                            </button>
                        </Link>
                    </div>
                    {/* Price Details Section */}
                    <div className="flex flex-col rounded-lg bg-white shadow-lg mt-8 px-8 py-4 border items-start">
                        <p className="py-2 font-bold text-lg">{t("PriceDetails")}</p>

                        <CustomRowText
                            label={`MRP (${priceDetails.itemsCount} Items)`}
                            value={`₹${parseInt(priceDetails.mrp) || priceDetails.mrp}`}
                        />

                        <CustomRowText
                            label={t("Discount")}
                            value={`- ₹${priceDetails.discount}`}
                            valueColor="text-green-600"
                        />

                        <CustomRowText
                            label={t("AdditionalDiscount")}
                            value={`₹${priceDetails.additionalDiscount}`}
                        />
                        {
                            priceDetails.deliveryCharges > 0 ?
                                <p className='text-primary text-end w-full text-sm'>Add products ₹{500 - priceDetails.totalPayable} more to get<span className='font-bold mx-2'>FREE</span>Delivery</p> : <></>
                        }
                        <CustomRowText
                            label={t("DeliveryCharges")}
                            value={`₹${priceDetails.deliveryCharges}`}
                            valueColor={priceDetails.deliveryCharges === 'FREE' ? 'text-green-600' : ''}
                        />

                        <CustomRowText
                            label={t("TotalSavings")}
                            value={`₹${parseInt(priceDetails.totalSavings) || priceDetails.totalSavings}`}
                            valueColor="text-green-600"
                        />
                        <hr className="my-2 border-black w-full border-1 border-dotted" />
                        <CustomRowText
                            label={t("TotalPayable")}
                            value={`₹${parseInt(priceDetails.totalPayable + priceDetails.deliveryCharges) || (priceDetails.totalPayable + priceDetails.deliveryCharges)}`}
                            labelFontWeight="font-bold"
                            valueFontWeight="font-bold"
                            labelColor="text-black"
                            valueColor="text-green-700"
                        />
                    </div>
                    <div className="flex flex-row justify-end mt-2">
                        <p className='text-md text-red-700 font-bold'>
                            {t("Get5additionaldiscountonPrepaidorders")}
                        </p>
                    </div>
                    <div className="flex flex-col sm:flex-row items-end gap-2 justify-end mt-2 sm:items-center">
                        <span className='text-primary text-md font-semibold mr-1'>
                            {t("youSaved")}
                        </span>
                        <span className='text-primary text-lg font-bold mr-4'>
                            ₹{parseInt(priceDetails.totalSavings) || priceDetails.totalSavings}
                        </span>
                        <Link to="/order" className='w-full sm:w-max'>
                            <button
                                className={`bg-green-500 ${isTab ? "w-full" : ""} text-white py-2 px-8 rounded hover:bg-green-700 transition-colors font-bold text-xl`}
                            >
                                {t("placeOrder")}
                            </button>
                        </Link>
                    </div>
                    <p className="text-gray-600 mt-4 text-justify mb-6 text-xs italic">
                        {t("cartBottomLine")}
                        <span className="text-primary font-semibold cursor-pointer underline">Terms & Conditions</span>{' '}
                    </p>
                    <div className="flex flex-col justify-center items-center text-center w-full">
                        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30 53.334C32.8346 55.435 36.282 56.6673 40 56.6673C43.718 56.6673 47.1653 55.435 50 53.334" stroke="#9FAFA4" stroke-width="4" stroke-linecap="round" />
                            <path d="M51.3346 35C51.3346 36.0409 51.0498 36.8928 50.6942 37.4261C50.3294 37.9734 50.045 38 50.0013 38C49.9576 38 49.6732 37.9734 49.3084 37.4261C48.9529 36.8928 48.668 36.0409 48.668 35C48.668 33.959 48.9529 33.1071 49.3084 32.5739C49.6732 32.0266 49.9576 32 50.0013 32C50.045 32 50.3294 32.0266 50.6942 32.5739C51.0497 33.1071 51.3346 33.959 51.3346 35Z" fill="#1C274C" stroke="#9FAFA4" stroke-width="4" />
                            <path d="M31.3346 35C31.3346 36.0409 31.0497 36.8929 30.6942 37.4261C30.3294 37.9734 30.045 38 30.0013 38C29.9576 38 29.6732 37.9734 29.3084 37.4261C28.9529 36.8929 28.668 36.0409 28.668 35C28.668 33.9591 28.9529 33.1071 29.3084 32.5739C29.6732 32.0266 29.9576 32 30.0013 32C30.045 32 30.3294 32.0266 30.6942 32.5739C31.0497 33.1071 31.3346 33.9591 31.3346 35Z" fill="#1C274C" stroke="#9FAFA4" stroke-width="4" />
                            <path d="M23.3346 11.1254C28.2375 8.28925 33.93 6.66602 40.0013 6.66602C58.4106 6.66602 73.3346 21.5898 73.3346 39.9993C73.3346 58.4087 58.4106 73.3327 40.0013 73.3327C21.5918 73.3327 6.66797 58.4087 6.66797 39.9993C6.66797 33.928 8.2912 28.2356 11.1274 23.3327" stroke="#9FAFA4" stroke-width="4" stroke-linecap="round" />
                        </svg>
                        <p className='text-[#9FAFA4] text-2xl font-bold'>100% Geniune Agro Products</p>
                    </div>
                    <div className="flex flex-row justify-between px-4 mt-4">
                        <p className='text-center text-xl my-4 font-bold'>{t("topOffer")}</p>
                        <Link to="/view/products?utm=Top Offers" className="text-primary hover:text-gray-900 underline decoration-primary">View All</Link>
                    </div>
                    <HorizontalList children={products.map((product) => (
                        <ProductCard key={product.id} product={product} />
                    ))} />
                </>
            )}
        </div>
    );
};

export default Cart;
